import { Formik, FormikHelpers } from "formik";
import React, { useCallback } from "react";
import { Button, Card, Col, Form, Image, Row } from "react-bootstrap";
import { useCollection, useDocumentData } from "react-firebase-hooks/firestore";
import Loading from "../common/Loading";
import firebase, { db, storage } from "../firebase";

const FeedItem: React.FunctionComponent<{ shortcode?: string }> = ({
  shortcode = "",
}) => {
  const itemRef = db.collection("feed").doc(shortcode);

  const [item] = useDocumentData(itemRef);

  const url = `https://www.instagram.com/p/${shortcode}/`;

  const post = item as any;

  const remove = useCallback(() => {
    itemRef.delete();
  }, [itemRef]);

  return (
    <Row className="my-3">
      {post && (
        <Col xs="auto">
          <Image src={post.thumbnail_url} width={100} />
        </Col>
      )}
      {post && (
        <Col>
          <a href={url}>{post.caption}</a>
        </Col>
      )}
      <Col xs="auto">
        <Button onClick={remove}>Remove</Button>
      </Col>
    </Row>
  );
};

const initialValues = { shortcode: "", caption: "" };

const AddShortcode = () => {
  const feedRef = db.collection("feed");

  const submit = useCallback(
    async (
      values: typeof initialValues,
      helpers: FormikHelpers<typeof initialValues>
    ) => {
      const shortcode = values.shortcode
        .replace("https://", "")
        .replace("http://", "")
        .replace("www.", "")
        .replace("instagram.com", "")
        .replace("/p", "")
        .replace(/\//g, "");

      const url = `https://www.instagram.com/p/${shortcode}/`;

      const response = await fetch(
        `https://graph.facebook.com/v10.0/instagram_oembed?access_token=869768337204970|17f10f3e394fddba16473569ebff1183&url=${url}&hidecaption=false`
      );
      const body = await response.json();

      const ref = storage.ref().child(`images/insta_${shortcode}.jpg`);

      const thumbnail_response = await fetch(body.thumbnail_url);

      const blob = await thumbnail_response.blob();
      ref.put(blob);

      try {
        await feedRef.doc(shortcode).set(
          {
            ...body,
            caption: values.caption,
            created: new Date(),
          },
          { merge: true }
        );

        helpers.resetForm();
      } catch (e) {
        console.error(e);
      }
    },
    [feedRef]
  );

  return (
    <Formik initialValues={initialValues} onSubmit={submit}>
      {({
        values,
        errors,
        touched,
        handleChange,
        handleBlur,
        handleSubmit,
        isSubmitting,
      }) => (
        <Form.Row className="mb-5">
          <Col>
            <Form.Label>Instagram post URL</Form.Label>
            <Form.Control
              name="shortcode"
              placeholder="https://www.instagram.com/p/{media-shortcode}/"
              onChange={handleChange}
              onBlur={handleBlur}
              value={values.shortcode}
            />
          </Col>
          <Col>
            <Form.Label>Caption</Form.Label>
            <Form.Control
              name="caption"
              placeholder="Copy the caption from the post (not in oEmbed API 😑)"
              onChange={handleChange}
              onBlur={handleBlur}
              value={values.caption}
            />
          </Col>
          <Col
            xs="auto"
            className="d-flex justify-content-end align-content-end align-items-end"
          >
            <Button variant="secondary" onClick={() => handleSubmit()}>
              Add
            </Button>
          </Col>
        </Form.Row>
      )}
    </Formik>
  );
};

const ManageInstaFeed: React.FunctionComponent = () => {
  const feedRef = db.collection("feed").orderBy("created", "desc").limit(8);

  const [data, loading] = useCollection(feedRef);

  if (loading) {
    return <Loading />;
  }

  const feed = data?.docs || [];

  return (
    <Card className="my-3 text-dark">
      <Card.Header as="h5">Instagram Feed</Card.Header>
      <Card.Body>
        <AddShortcode />
        {feed.map((item: firebase.firestore.DocumentSnapshot) => (
          <FeedItem key={item.id} shortcode={item.id} />
        ))}
      </Card.Body>
    </Card>
  );
};

export default ManageInstaFeed;
