import { Formik, FormikHelpers } from "formik";
import React from "react";
import { Alert, Button, Container, Form } from "react-bootstrap";
import { auth } from "../firebase";

interface Fields {
  username: string;
  password: string;
}

const signIn = (data: Fields, actions: FormikHelpers<Fields>) =>
  auth
    .signInWithEmailAndPassword(data.username, data.password)
    .catch((e) => actions.setStatus(e.message));

const SignIn: React.FunctionComponent = () => {
  return (
    <div className="admin-padding">
      <Container className="my-3">
        <h1>Admin page</h1>
        <h2>Sign in</h2>
        <Formik
          initialValues={{ username: "", password: "" }}
          onSubmit={signIn}
        >
          {({
            handleSubmit,
            handleChange,
            handleBlur,
            values,
            isSubmitting,
            status,
          }) => (
            <Form onSubmit={handleSubmit}>
              <Form.Group>
                <Form.Label>Email</Form.Label>
                <Form.Control
                  name="username"
                  type="email"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.username}
                />
              </Form.Group>
              <Form.Group>
                <Form.Label>Password</Form.Label>
                <Form.Control
                  name="password"
                  type="password"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.password}
                />
              </Form.Group>
              {status && <Alert variant="danger">{status}</Alert>}
              <Button type="submit" disabled={isSubmitting}>
                Sign in
              </Button>
            </Form>
          )}
        </Formik>
      </Container>
    </div>
  );
};

export default SignIn;
