import React from "react";
import { useQuery } from "react-query";
import { useRouteMatch } from "react-router";
import Header from "../common/Header";
import Loading from "../common/Loading";
import Section from "../common/Section";
import ErrorPage from "../ErrorPage";
import { db } from "../firebase";

const GenericPage: React.FunctionComponent = () => {
  const route = useRouteMatch<{ page?: string }>();
  const slug = route.params.page;

  const pageRef = db.collection("pages").doc(slug);
  const sectionsRef = pageRef.collection("sections").orderBy("order");

  const pageQuery = useQuery(slug, () => pageRef.get().then((r) => r.data()));
  const sectionsQuery = useQuery([slug, "sections"], () => sectionsRef.get());

  const page = pageQuery.data as {
    title: string;
    subtitle: string;
    image: string;
    position: "top" | "center" | "bottom";
  };

  const sections = sectionsQuery.data?.docs;

  if (pageQuery.isLoading || sectionsQuery.isLoading) {
    return <Loading />;
  }

  if (!page || !sections) {
    return <ErrorPage />;
  }

  return (
    <>
      <Header
        title={page.title}
        subtitle={page.subtitle}
        image={page.image}
        position={page.position || "center"}
      />

      <div className="page">
        {sections.map((sectionDoc) => {
          const section = sectionDoc.data();

          return (
            <Section
              key={sectionDoc.id}
              title={section.title}
              subtitle={section.subtitle}
              roundedImages={section.roundedImages}
              image={section.images || section.image}
            >
              {section.content}
            </Section>
          );
        })}
      </div>
    </>
  );
};

export default GenericPage;
