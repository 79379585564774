import { Field, Formik } from "formik";
import React, { useCallback } from "react";
import { Button, Card, Col, Form, Nav } from "react-bootstrap";
import { useCollection, useDocumentData } from "react-firebase-hooks/firestore";
import { NavLink, Route, Switch, useRouteMatch } from "react-router-dom";
import Loading from "../common/Loading";
import Uploader from "../common/Uploader";
import { db } from "../firebase";
import EditSection from "./EditSection";
import "./index.scss";
import NewSection from "./NewSection";

const EditPage: React.FunctionComponent = () => {
  const route = useRouteMatch<{ page: string }>();

  const pageRef = db.collection("pages").doc(route.params.page);
  const sectionsRef = pageRef.collection("sections").orderBy("order");

  const [pageData] = useDocumentData(pageRef);
  const [sections] = useCollection(sectionsRef);

  const handleSubmit = useCallback(
    async (values) => await pageRef.update(values),
    [pageRef]
  );

  if (!pageData || !sections) {
    return (
      <Col xs={10}>
        <Loading />
      </Col>
    );
  }

  return (
    <>
      <Col xs={10}>
        <Card className="my-3 text-dark">
          <Card.Header as="h5">{(pageData as any).title}</Card.Header>
          <Card.Body>
            <Formik initialValues={pageData as any} onSubmit={handleSubmit}>
              {({
                values,
                handleChange,
                handleBlur,
                handleSubmit,
                isSubmitting,
              }) => (
                <Form onSubmit={handleSubmit}>
                  <Form.Group>
                    <Form.Label>Title</Form.Label>
                    <Form.Control
                      name="title"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.title}
                    />
                  </Form.Group>
                  <Form.Group>
                    <Form.Label>Subtitle</Form.Label>
                    <Form.Control
                      name="subtitle"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.subtitle}
                    />
                  </Form.Group>
                  <Form.Group>
                    <Form.Label>Image</Form.Label>
                    <Field
                      name="image"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.image}
                      component={Uploader}
                    />
                  </Form.Group>
                  <Form.Group>
                    <Form.Label>Image position</Form.Label>
                    <Form.Control
                      name="position"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.position}
                    />
                  </Form.Group>
                  <Button type="submit" disabled={isSubmitting}>
                    Save page
                  </Button>
                </Form>
              )}
            </Formik>
          </Card.Body>
        </Card>
        <Card className="my-3 text-dark">
          <Card.Header>
            <h5>{(pageData as any).title}: Sections</h5>
            <Nav variant="tabs">
              {sections.docs.map((doc: any) => {
                const data = doc.data();

                return (
                  <Nav.Item key={doc.id}>
                    <NavLink className="nav-link" to={`${route.url}/${doc.id}`}>
                      {data.title || "Untitled"}
                    </NavLink>
                  </Nav.Item>
                );
              })}
              <Nav.Item>
                <NavLink className="nav-link" to={`${route.url}/new`}>
                  +
                </NavLink>
              </Nav.Item>
            </Nav>
          </Card.Header>
          <Card.Body>
            <Switch>
              <Route path={`${route.path}/new`}>
                <NewSection />
              </Route>
              <Route path={`${route.path}/:section`}>
                <EditSection />
              </Route>
            </Switch>
          </Card.Body>
        </Card>
      </Col>
    </>
  );
};

export default EditPage;
