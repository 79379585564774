import React, { FunctionComponent } from "react";
import { Carousel, Col, Container, Row } from "react-bootstrap";
import ReactMarkdown from "react-markdown";
import CircleImage from "./CircleImage";
import ImageOrVideo, { SIZES } from "./ImageOrVideo";
import "./Section.scss";

interface Props {
  title: string;
  subtitle?: string;
  image?: string | ReadonlyArray<string>;
  roundedImages?: boolean;
}

const Section: FunctionComponent<Props> = ({
  title,
  subtitle,
  image,
  roundedImages,
  children,
}) => {
  const images = Array.isArray(image) ? image : [image];

  return (
    <section className="Section my-3">
      <Container>
        <Row>
          {images && (
            <Col className="image-col" md={3}>
              {images.map((url) => {
                return roundedImages ? (
                  <CircleImage key={url} className="my-3" fluid src={url} />
                ) : (
                  <ImageOrVideo
                    key={url}
                    className="my-3"
                    width={255}
                    imageSizes={SIZES}
                    fluid
                    src={url}
                    rounded
                  />
                );
              })}
            </Col>
          )}

          <Col>
            <h3 className="mt-3 display-4">{title}</h3>
            {subtitle && <h4>{subtitle}</h4>}
            {images && !roundedImages && (
              <Carousel>
                {images.map((url) => (
                  <Carousel.Item key={url}>
                    <ImageOrVideo
                      height={250}
                      width={510}
                      className="my-3"
                      imageSizes={SIZES}
                      fluid
                      src={url}
                      rounded
                    />
                  </Carousel.Item>
                ))}
              </Carousel>
            )}
            {typeof children === "string" && (
              <ReactMarkdown renderers={{ image: ImageOrVideo }}>
                {children}
              </ReactMarkdown>
            )}
          </Col>
        </Row>
      </Container>
    </section>
  );
};

export default Section;
