import React from "react";
import { Container } from "react-bootstrap";
import { useQuery } from "react-query";
import Header from "../common/Header";
import InstaFeed from "../common/InstaFeed";
import Loading from "../common/Loading";
import SCBorder from "../common/SCBorder";
import ErrorPage from "../ErrorPage";
import { db } from "../firebase";
import SubNav from "./SubNav";

const Home = () => {
  const pageRef = db.collection("pages").doc("home");
  const aboutRef = db.collection("pages").doc("about");

  const pageQuery = useQuery("home", () => pageRef.get().then((r) => r.data()));
  const about = useQuery("about", () => aboutRef.get().then((r) => r.data()));

  const page = pageQuery.data as {
    title: string;
    subtitle: string;
    image: string;
    position?: "top" | "center" | "bottom";
  };

  if (pageQuery.isLoading) {
    return <Loading />;
  }

  if (!page) {
    return <ErrorPage />;
  }

  return (
    <>
      <Header
        title={page.title}
        subtitle={page.subtitle}
        image={page.image}
        position={page.position}
      />
      <Container className="my-3">
        <SubNav
          extras={[
            {
              title: about.data?.title,
              url: "/about",
              image: about.data?.image,
            },
          ]}
        />
      </Container>

      <SCBorder />

      <Container className="my-3">
        <h3 className="text-center display-4">Recent updates</h3>
        <InstaFeed />
      </Container>
    </>
  );
};
export default Home;
