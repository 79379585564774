import React from "react";
import { Container } from "react-bootstrap";
import { useQuery } from "react-query";
import { useRouteMatch } from "react-router-dom";
import Loading from "../common/Loading";
import Section from "../common/Section";
import { db } from "../firebase";

const Service: React.FunctionComponent = () => {
  const route = useRouteMatch<{ section: string }>();

  const serviceRef = db
    .collection("pages")
    .doc("services")
    .collection("sections")
    .doc(route.params.section);

  const query = useQuery(["services", route.params.section], () =>
    serviceRef.get().then((r) => r.data())
  );

  const service = query.data as any;

  const testimonials = service?.testimonials || [];

  if (!service) {
    return <Loading />;
  }

  return (
    <>
      <Section
        title={service.title}
        subtitle={service.subtitle}
        roundedImages={service.roundedImages}
        image={service.images || service.image}
      >
        {service.content}
      </Section>
      {testimonials.map((testimonial: any) => (
        <Container key={testimonial.from}>
          <blockquote className="blockquote text-center p-3">
            <p className="mb-0">“{testimonial.quote}”</p>
            <footer className="blockquote-footer">
              <cite title={testimonial.from}>{testimonial.from}</cite>
            </footer>
          </blockquote>
        </Container>
      ))}
    </>
  );
};

export default Service;
