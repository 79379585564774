import { FieldArray, Formik, Field } from "formik";
import React, { useCallback } from "react";
import { Button, Col, Form, Row } from "react-bootstrap";
import { useDocumentData } from "react-firebase-hooks/firestore";
import { useHistory, useRouteMatch } from "react-router-dom";
import Loading from "../common/Loading";
import Uploader from "../common/Uploader";
import { db } from "../firebase";
import "./index.scss";

const EditSection: React.FunctionComponent = () => {
  const route = useRouteMatch<{ page: string; section: string }>();
  const history = useHistory();

  const sectionsRef = db
    .collection("pages")
    .doc(route.params.page)
    .collection("sections");
  const sectionRef = sectionsRef.doc(route.params.section);

  const [value, loading] = useDocumentData(sectionRef);

  const handleSubmit = useCallback(
    async (values) => {
      const sectionId = values.id || route.params.section;
      const ref = sectionsRef.doc(sectionId);

      if (sectionId === route.params.section) {
        await ref.update(values);
      } else {
        const doc = await ref.get();

        if (doc.exists) {
          alert("A section already exists with this path.");
          return;
        }

        await ref.set(values);
        await sectionsRef.doc(route.params.section).delete();
        history.replace(sectionId);
      }
    },
    [route.params.section, sectionsRef, history]
  );

  const handleDelete = useCallback(async () => {
    await sectionRef.delete();
    history.replace(".");
  }, [sectionRef, history]);

  if (!value || loading) {
    return <Loading />;
  }

  return (
    <Formik initialValues={value as any} onSubmit={handleSubmit}>
      {({ values, handleChange, handleBlur, handleSubmit, isSubmitting }) => (
        <Form onSubmit={handleSubmit}>
          <Form.Group>
            <Form.Label>Title</Form.Label>
            <Form.Control
              name="title"
              onChange={handleChange}
              onBlur={handleBlur}
              value={values.title}
            />
          </Form.Group>
          <Form.Group>
            <Form.Label>Subtitle</Form.Label>
            <Form.Control
              name="subtitle"
              onChange={handleChange}
              onBlur={handleBlur}
              value={values.subtitle}
            />
          </Form.Group>
          <Form.Group>
            <Form.Label>Path</Form.Label>
            <Form.Control
              name="id"
              onChange={handleChange}
              onBlur={handleBlur}
              value={values.id}
            />
          </Form.Group>
          <Form.Group>
            <Form.Label>Content</Form.Label>
            <Form.Control
              name="content"
              onChange={handleChange}
              onBlur={handleBlur}
              value={values.content}
              as="textarea"
              rows={10}
            />
            <Form.Text>
              Use <a href="https://commonmark.org/help/">Markdown</a> to use
              **bold**, _italics_ or many other formatting options.
            </Form.Text>
          </Form.Group>
          <Form.Group>
            <Form.Label>Order</Form.Label>
            <Form.Control
              name="order"
              onChange={handleChange}
              onBlur={handleBlur}
              value={values.order}
            />
            <Form.Text>
              The sections/tabs will be ordered by this - e.g. 1 2 3.
            </Form.Text>
          </Form.Group>
          <Form.Group>
            <Form.Check
              name="roundedImages"
              onChange={handleChange}
              onBlur={handleBlur}
              value={values.roundedImages}
              type="checkbox"
              label="Circular images"
            />
            <Form.Text>Makes the images circles</Form.Text>
          </Form.Group>
          <FieldArray
            name="images"
            render={(helpers) => (
              <div>
                {values.images?.map((image: string, index: number) => (
                  <Form.Row key={index} className="my-3">
                    <Col>
                      <Field
                        name={`images.${index}`}
                        placeholder="url/to/image"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.images[index]}
                        component={Uploader}
                      />
                    </Col>
                    <Col xs="auto" className="align-right">
                      <Button
                        variant="secondary"
                        onClick={() => helpers.remove(index)}
                      >
                        -
                      </Button>
                    </Col>
                  </Form.Row>
                ))}
                <Row className="my-3 ">
                  <Col></Col>
                  <Col xs="auto">
                    <Button
                      type="button"
                      variant="secondary"
                      onClick={() => helpers.push("")}
                    >
                      Add image
                    </Button>
                  </Col>
                </Row>
              </div>
            )}
          />
          <FieldArray
            name="testimonials"
            render={(helpers) => (
              <div>
                {values.testimonials?.map((testimonial: any, index: number) => (
                  <Form.Row key={index} className="my-3">
                    <Col xs={3}>
                      <Form.Group>
                        <Form.Control
                          name={`testimonials.${index}.from`}
                          placeholder="From"
                          onChange={handleChange}
                          onBlur={handleBlur}
                          value={values.testimonials[index].from}
                        />
                      </Form.Group>
                    </Col>
                    <Col>
                      <Form.Group>
                        <Form.Control
                          name={`testimonials.${index}.quote`}
                          placeholder="Quote"
                          onChange={handleChange}
                          onBlur={handleBlur}
                          value={values.testimonials[index].quote}
                          as="textarea"
                        />
                      </Form.Group>
                    </Col>
                    <Col xs="auto" className="align-right">
                      <Button
                        variant="secondary"
                        onClick={() => helpers.remove(index)}
                      >
                        -
                      </Button>
                    </Col>
                  </Form.Row>
                ))}
                <Row className="my-3 ">
                  <Col></Col>
                  <Col xs="auto">
                    <Button
                      type="button"
                      variant="secondary"
                      onClick={() => helpers.push({ from: "", quote: "" })}
                    >
                      Add testimonial
                    </Button>
                  </Col>
                </Row>
              </div>
            )}
          />
          <Button type="submit" disabled={isSubmitting}>
            Save section
          </Button>
          <Button
            className="ml-3"
            type="button"
            variant="danger"
            disabled={isSubmitting}
            onClick={handleDelete}
          >
            Delete section
          </Button>
        </Form>
      )}
    </Formik>
  );
};

export default EditSection;
