import React from "react";
import { useQuery } from "react-query";
import { Link } from "react-router-dom";
import Loading from "../common/Loading";
import ErrorPage from "../ErrorPage";
import { db } from "../firebase";
import "./SubNav.scss";

export interface Service {
  title: string;
  subtitle?: string;
  image?: string;
  images?: ReadonlyArray<string>;
  url?: string;
}

const SubNavItem: React.FunctionComponent<{ id?: string; item: Service }> = ({
  id,
  item,
}) => {
  const image = item?.images ? item?.images[0] : item?.image || "";

  const srcParts = image.split(".");

  const path = srcParts.slice(0, -1).join(".");
  const ext = srcParts[srcParts.length - 1];

  return (
    <Link to={item.url || `/services/${id}`}>
      <div
        className="content"
        style={{ backgroundImage: `url(${path}_255x999.${ext})` }}
      >
        <div className="text">{item.title}</div>
      </div>
    </Link>
  );
};

const SubNav: React.FunctionComponent<{
  extras?: ReadonlyArray<Service>;
  extrasPlacement?: "before" | "after";
}> = ({ extras = [], extrasPlacement = "before" }) => {
  const sectionsRef = db
    .collection("pages")
    .doc("services")
    .collection("sections")
    .orderBy("order");

  const sectionsQuery = useQuery(["services", "sections"], () =>
    sectionsRef.get()
  );

  const sections = sectionsQuery.data?.docs || [];

  if (sectionsQuery.isLoading) {
    return <Loading />;
  }

  if (!sections) {
    return <ErrorPage />;
  }

  return (
    <div className="SubNav">
      {extrasPlacement === "before" &&
        extras.map((service: Service) => (
          <SubNavItem key={service.title} item={service} />
        ))}
      {sections.map((service: any) => {
        const data = service.data();

        return <SubNavItem key={service.id} id={service.id} item={data} />;
      })}
      {extrasPlacement === "after" &&
        extras.map((service: Service) => (
          <SubNavItem key={service.title} item={service} />
        ))}
    </div>
  );
};

export default SubNav;
