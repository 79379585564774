import classNames from "classnames";
import React from "react";
import { ImageProps } from "react-bootstrap";
import "./CircleImage.scss";
import ImageOrVideo from "./ImageOrVideo";

const CircleImage: React.FunctionComponent<ImageProps> = ({
  className,
  ...props
}) => (
  <div className={classNames("CircleImage", className)}>
    <ImageOrVideo roundedCircle {...props} />
  </div>
);

export default CircleImage;
