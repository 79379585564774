import React from "react";
import { Image } from "react-bootstrap";
import { useQuery } from "react-query";
import { db } from "../firebase";
import "./InstaFeed.scss";
import Loading from "./Loading";

const BASE_URL =
  "https://firebasestorage.googleapis.com/v0/b/scproductions-site.appspot.com/o/images%2Finsta_";
const QUERY = ".jpg?alt=media&token=432f0a8b-e4d2-4bf6-9897-2b93d9426e30";

const InstaImage: React.FunctionComponent<{
  caption: string;
  url: string;
  sourceUrl: string;
}> = ({ caption, url, sourceUrl }) => (
  <a className="InstaImage" href={url}>
    <div className="content">
      <Image
        src={sourceUrl}
        alt={caption}
        rounded
        height={220}
        style={{ objectFit: "cover" }}
      />
      <div className="insta-caption p-3">{caption}</div>
    </div>
  </a>
);

const InstaFeed: React.FunctionComponent = () => {
  const feedRef = db.collection("feed").orderBy("created", "desc").limit(12);

  const feedQuery = useQuery("feed", () => feedRef.get());

  if (feedQuery.isLoading) {
    return <Loading />;
  }

  const feed = feedQuery.data?.docs || [];

  return (
    <div className="InstaFeed">
      {feed.map((imageDoc) => {
        const image = imageDoc.data();

        const url = image.url || `https://www.instagram.com/p/${imageDoc.id}/`;

        return (
          <InstaImage
            key={imageDoc.id}
            caption={image.caption}
            url={url}
            sourceUrl={`${BASE_URL}${imageDoc.id}${QUERY}`}
          />
        );
      })}
    </div>
  );
};

export default InstaFeed;
