import "./ErrorPage.scss";
import React from "react";

const ErrorPage: React.FunctionComponent = () => (
  <div className="ErrorPage display-4 text-center">
    Oops, couldn't find that page.
  </div>
);

export default ErrorPage;
