import React from "react";
import { Container, Nav, Navbar, Image } from "react-bootstrap";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  NavLink,
} from "react-router-dom";
import Footer from "./common/Footer";
import Home from "./home";
import ErrorPage from "./ErrorPage";
import Services from "./services";
import AdminPage from "./admin";
import GenericPage from "./common/GenericPage";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faFacebook,
  faInstagram,
  faLinkedin,
  faTwitter,
} from "@fortawesome/free-brands-svg-icons";

function App() {
  return (
    <Router>
      <div
        className="min-vh-100"
        style={{ display: "flex", flexDirection: "column", minHeight: "100%" }}
      >
        <Navbar
          collapseOnSelect
          bg="dark"
          variant="dark"
          fixed="top"
          expand="lg"
        >
          <Container>
            <Navbar.Brand href="/">
              <Image
                height="30"
                width="160"
                src="/images/logo-flat-white.svg"
                alt="logo"
              />
            </Navbar.Brand>
            <Navbar.Toggle />
            <Navbar.Collapse>
              <Nav className="ml-auto">
                <Nav.Item>
                  <Nav.Link eventKey="1.1" as={NavLink} to="/" exact>
                    Home
                  </Nav.Link>
                </Nav.Item>
                <Nav.Item>
                  <Nav.Link eventKey="1.2" as={NavLink} to="/about">
                    About
                  </Nav.Link>
                </Nav.Item>
                <Nav.Item>
                  <Nav.Link eventKey="1.3" as={NavLink} to="/services">
                    Services
                  </Nav.Link>
                </Nav.Item>
                <Nav.Item>
                  <Nav.Link eventKey="1.4" as={NavLink} to="/team">
                    Team
                  </Nav.Link>
                </Nav.Item>
                <Nav.Item>
                  <Nav.Link eventKey="1.5" as={NavLink} to="/events">
                    Our Work
                  </Nav.Link>
                </Nav.Item>
                <Nav.Item>
                  <Nav.Link eventKey="1.6" as={NavLink} to="/vacancies">
                    Current Vacancies
                  </Nav.Link>
                </Nav.Item>
                <Nav.Item>
                  <Nav.Link eventKey="1.7" as={NavLink} to="/contact">
                    Contact
                  </Nav.Link>
                </Nav.Item>
              </Nav>
              <Nav>
                <Nav.Item>
                  <Nav.Link href="https://www.facebook.com/sc.prod.1">
                    <FontAwesomeIcon icon={faFacebook} />
                    <span className="d-lg-none"> Facebook</span>
                  </Nav.Link>
                </Nav.Item>
                <Nav.Item>
                  <Nav.Link href="https://twitter.com/SCProdltd">
                    <FontAwesomeIcon icon={faTwitter} />
                    <span className="d-lg-none"> Twitter</span>
                  </Nav.Link>
                </Nav.Item>
                <Nav.Item>
                  <Nav.Link href="https://www.instagram.com/sc_productions_ltd/">
                    <FontAwesomeIcon icon={faInstagram} style={{ width: 16 }} />
                    <span className="d-lg-none"> Instagram</span>
                  </Nav.Link>
                </Nav.Item>
                <Nav.Item>
                  <Nav.Link href="https://www.linkedin.com/company/sc-productions">
                    <FontAwesomeIcon icon={faLinkedin} style={{ width: 16 }} />
                    <span className="d-lg-none"> LinkedIn</span>
                  </Nav.Link>
                </Nav.Item>
              </Nav>
            </Navbar.Collapse>
          </Container>
        </Navbar>

        <div style={{ flex: 1, display: "flex", flexDirection: "column" }}>
          <Switch>
            <Route exact path="/">
              <Home />
            </Route>
            <Route path="/services">
              <Services />
            </Route>
            <Route path="/admin">
              <AdminPage />
            </Route>
            <Route path="/:page">
              <GenericPage />
            </Route>
            <Route>
              <ErrorPage />
            </Route>
          </Switch>
        </div>

        <Footer />
      </div>
    </Router>
  );
}

export default App;
