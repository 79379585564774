import React from "react";
import { Container, Nav, Navbar } from "react-bootstrap";
import { useQuery } from "react-query";
import { NavLink, Route, Switch, useRouteMatch } from "react-router-dom";
import Header from "../common/Header";
import Loading from "../common/Loading";
import ErrorPage from "../ErrorPage";
import { db } from "../firebase";
import SubNav from "../home/SubNav";
import Service from "./Service";

const Services = () => {
  const route = useRouteMatch<{ section: string }>();

  const pageRef = db.collection("pages").doc("services");
  const eventsRef = db.collection("pages").doc("events");
  const sectionsRef = pageRef.collection("sections").orderBy("order");

  const pageQuery = useQuery("services", () =>
    pageRef.get().then((r) => r.data())
  );
  const events = useQuery("events", () =>
    eventsRef.get().then((r) => r.data())
  );
  const sectionsQuery = useQuery(["services", "sections"], () =>
    sectionsRef.get()
  );

  const page = pageQuery.data as {
    title: string;
    subtitle: string;
    image: string;
    position?: "top" | "center" | "bottom";
  };

  const sections = sectionsQuery.data?.docs;

  if (pageQuery.isLoading || sectionsQuery.isLoading) {
    return <Loading />;
  }

  if (!page || !sections) {
    return <ErrorPage />;
  }

  return (
    <>
      <Header
        title={page.title}
        subtitle={page.subtitle}
        image={page.image}
        position={page.position}
      />

      <Navbar bg="dark" variant="dark" className="navbar-custom">
        <Container>
          <Nav className="flex-wrap w-100 justify-content-around">
            {sections?.map((service: any) => {
              const data = service.data();

              return (
                <Nav.Item key={service.id}>
                  <Nav.Link as={NavLink} to={`/services/${service.id}`}>
                    {data.title}
                  </Nav.Link>
                </Nav.Item>
              );
            })}
          </Nav>
        </Container>
      </Navbar>

      <Switch>
        <Route path={`${route.path}/:section`}>
          <Service />
        </Route>
        <Route>
          <Container>
            <SubNav
              extras={[
                {
                  title: events.data?.title,
                  url: "/events",
                  image: events.data?.image,
                },
              ]}
              extrasPlacement="after"
            />
          </Container>
        </Route>
      </Switch>
    </>
  );
};

export default Services;
