import React from "react";

const Footer: React.FunctionComponent = () => (
  <footer className="mt-3 bg-dark">
    <div className="container">
      <p className="m-0 py-5 text-center text-light small">
        Copyright &copy; SC Productions 2022
      </p>
    </div>
  </footer>
);

export default Footer;
