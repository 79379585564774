import classNames from "classnames";
import React from "react";
import { Image, ImageProps } from "react-bootstrap";

interface OwnProps {
  imageSizes?: ReadonlyArray<{ width: number; height: number }>;
  height?: React.CSSProperties["height"];
}

interface VideoProps {
  autoPlay?: boolean;
  muted?: boolean;
  loop?: boolean;
  poster?: string;
  controls?: boolean;
}

type Props = OwnProps & ImageProps & VideoProps;

export const SIZES = [
  { width: 255, height: 999 },
  { width: 510, height: 999 },
  { width: 576, height: 576 },
  { width: 768, height: 768 },
  { width: 992, height: 992 },
  { width: 1200, height: 1200 },
  { width: 1400, height: 1400 },
];

const ImageOrVideo: React.FunctionComponent<Props> = ({
  autoPlay = true,
  muted = true,
  loop = true,
  poster,
  controls = true,
  height,
  width,
  imageSizes = [],
  src,
  ...imageProps
}) => {
  const heightStyle: React.CSSProperties = height
    ? { height, width: "100%", objectFit: "cover" }
    : {};

  if (src && src.includes(".mp4")) {
    return (
      <video
        className={classNames(imageProps.className, {
          "img-fluid": imageProps.fluid,
          rounded: imageProps.rounded || imageProps.rounded === undefined,
          "rounded-circle": imageProps.roundedCircle,
          "img-thumbnail": imageProps.thumbnail,
        })}
        controls={controls}
        autoPlay={autoPlay}
        muted={muted}
        loop={loop}
        style={{ ...heightStyle, ...imageProps.style }}
      >
        <source type="video/mp4" src={src} />
      </video>
    );
  }

  return (
    <Image
      src={src}
      srcSet={generateSrcSet(src, imageSizes)}
      sizes={`${width}px`}
      width={width}
      height={height || width}
      alt={imageProps.alt}
      {...imageProps}
      fluid={imageProps.fluid || imageProps.fluid === undefined}
      rounded={imageProps.rounded || imageProps.rounded === undefined}
      style={{
        ...heightStyle,
        ...imageProps.style,
      }}
    />
  );
};

export const generateSrcSet = (
  src: string | undefined,
  sizes: ReadonlyArray<{ width: number; height: number }>
) => {
  if (!src) {
    return "";
  }

  const srcParts = src.split(".");

  const path = srcParts.slice(0, -1).join(".");
  const ext = srcParts[srcParts.length - 1];

  return [
    ...sizes.map(
      (size) => `${path}_${size.width}x${size.height}.${ext} ${size.width}w`
    ),
    `${src}`,
  ].join(", ");
};

export default ImageOrVideo;
