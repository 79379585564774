import firebase from "firebase/app";
import firebaseConfig from "./firebase.config.json";
import "firebase/analytics";
import "firebase/auth";
import "firebase/firestore";
import "firebase/storage";

firebase.initializeApp(firebaseConfig);

export const auth = firebase.auth();
export const db = firebase.firestore();
export const storage = firebase.storage();

db.enablePersistence();

export default firebase;
