import { FieldProps } from "formik";
import React, { ChangeEvent, useCallback, useState } from "react";
import { Button, Col, Form } from "react-bootstrap";
import { storage } from "../firebase";
import ImageOrVideo from "./ImageOrVideo";
import Loading from "./Loading";

const Uploader: React.FunctionComponent<FieldProps> = ({ field, form }) => {
  const [uploading, setUploading] = useState(false);

  const handleChange = useCallback(
    async (event: ChangeEvent<HTMLInputElement>) => {
      const file = event?.target?.files;
      console.log(event);

      if (file && file[0]) {
        const ref = storage.ref().child(`images/${file[0].name}`);

        try {
          setUploading(true);
          const response = await ref.put(file[0]);
          console.log(response);
          const url = await response.ref.getDownloadURL();
          form.setFieldValue(field.name, url);
        } catch (e) {
          alert(e.message);
          console.log(e);
        } finally {
          setUploading(false);
        }
      }
    },
    [field, form, setUploading]
  );

  if (uploading) {
    return (
      <Form.Row>
        <Col
          xs="2"
          key="image"
          style={{ filter: "grayscale(100%)", transition: "filter 2s" }}
        >
          <ImageOrVideo src={field.value} fluid thumbnail />
        </Col>
        <Col>
          <Loading />
        </Col>
      </Form.Row>
    );
  }

  return (
    <Form.Row>
      <Col
        xs="2"
        key="image"
        style={{ filter: "grayscale(0%)", transition: "filter 200ms" }}
      >
        <ImageOrVideo src={field.value} fluid thumbnail />
      </Col>
      <Col>
        <Form.Group>
          <Form.Control {...field} />
        </Form.Group>
      </Col>
      <Col xs="2">
        <div className="custom-file" style={{ overflow: "hidden" }}>
          <input
            className="custom-file-input"
            type="file"
            onChange={handleChange}
          />
          <Button
            style={{
              position: "absolute",
              top: 0,
              left: 0,
              right: 0,
              zIndex: 1,
              width: "100%",
            }}
            disabled={uploading}
          >
            Upload
          </Button>
        </div>
      </Col>
    </Form.Row>
  );
};

export default Uploader;
