import React, { useEffect, useState } from "react";
import { Button, Col, Container, Nav, Row } from "react-bootstrap";
import { useQuery } from "react-query";
import { NavLink, Route, Switch, useRouteMatch } from "react-router-dom";
import Loading from "../common/Loading";
import ErrorPage from "../ErrorPage";
import firebase, { auth, db } from "../firebase";
import EditPage from "./EditPage";
import "./index.scss";
import ManageInstaFeed from "./ManageInstaFeed";
import SignIn from "./SignIn";

const signOut = () => auth.signOut();

const AdminPage: React.FunctionComponent = (props) => {
  const route = useRouteMatch();
  const [user, setUser] = useState<firebase.User | undefined | null>();

  useEffect(() => auth.onAuthStateChanged(setUser), [setUser]);

  const pagesRef = db.collection("pages");
  const { isLoading, data } = useQuery("pages", () => pagesRef.get());

  if (user === null) {
    return <SignIn />;
  }

  if (!user || isLoading) {
    return <Loading />;
  }

  if (!data) {
    return <ErrorPage />;
  }

  return (
    <div className="admin-padding">
      <Container className="my-3">
        <h1>Admin page</h1>
        <Row>
          <Col>
            <Nav variant="pills" className="flex-column">
              {data.docs.map((doc) => {
                const data = doc.data();

                return (
                  <Nav.Item key={doc.id}>
                    <NavLink className="nav-link" to={`${route.url}/${doc.id}`}>
                      {data.title || "Untitled"}
                    </NavLink>
                  </Nav.Item>
                );
              })}
            </Nav>
          </Col>
          <Switch>
            <Route path={`${route.path}/:page`}>
              <EditPage />
            </Route>
          </Switch>
        </Row>
        <ManageInstaFeed />
        <Button onClick={signOut}>Sign out</Button>
      </Container>
    </div>
  );
};

export default AdminPage;
