import React from "react";
import { Container } from "react-bootstrap";
import SCBorder from "./SCBorder";
import "./Header.scss";
import ImageOrVideo, { SIZES } from "./ImageOrVideo";

interface Props {
  title: string;
  subtitle?: string;
  image?: string;
  position?: "top" | "center" | "bottom";
}

const Header: React.FunctionComponent<Props> = ({
  title,
  subtitle,
  image,
  children,
  position = "center",
}) => (
  <header className="Header">
    <div className="background">
      <ImageOrVideo
        src={image}
        fluid
        controls={false}
        width={1440}
        imageSizes={SIZES}
        sizes="100vw"
        style={{
          objectPosition: `center ${position}`,
        }}
      />
    </div>
    {children}
    <div className="content p-5">
      <Container>
        <h1 className="display-2 text-center text-light">{title}</h1>
        {subtitle && <h2 className="text-center text-light">{subtitle}</h2>}
      </Container>
    </div>
    <SCBorder />
  </header>
);

export default Header;
