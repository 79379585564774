import { Formik } from "formik";
import React, { useCallback } from "react";
import { Button, Form } from "react-bootstrap";
import { useHistory, useRouteMatch } from "react-router-dom";
import { db } from "../firebase";
import "./index.scss";

const NewSection: React.FunctionComponent = () => {
  const route = useRouteMatch<{ page: string; section: string }>();
  const history = useHistory();

  console.log(route);

  const sectionsRef = db
    .collection("pages")
    .doc(route.params.page)
    .collection("sections");

  const handleSubmit = useCallback(
    async (values) => {
      const result = await sectionsRef.add(values);
      return history.replace(result.id);
    },
    [sectionsRef, history]
  );

  return (
    <Formik
      initialValues={{ title: "", subtitle: "", content: "", order: 1 }}
      onSubmit={handleSubmit}
    >
      {({
        values,
        errors,
        touched,
        handleChange,
        handleBlur,
        handleSubmit,
        isSubmitting,
      }) => (
        <Form onSubmit={handleSubmit}>
          <Form.Group>
            <Form.Label>Title</Form.Label>
            <Form.Control
              name="title"
              onChange={handleChange}
              onBlur={handleBlur}
              value={values.title}
            />
          </Form.Group>
          <Form.Group>
            <Form.Label>Subtitle</Form.Label>
            <Form.Control
              name="subtitle"
              onChange={handleChange}
              onBlur={handleBlur}
              value={values.subtitle}
            />
          </Form.Group>
          <Form.Group>
            <Form.Label>Content</Form.Label>
            <Form.Control
              name="content"
              onChange={handleChange}
              onBlur={handleBlur}
              value={values.content}
              as="textarea"
              rows={10}
            />
            <Form.Text>
              Use <a href="https://commonmark.org/help/">Markdown</a> to use
              **bold**, _italics_ or many other formatting options.
            </Form.Text>
          </Form.Group>
          <Form.Group>
            <Form.Label>Order</Form.Label>
            <Form.Control
              name="order"
              onChange={handleChange}
              onBlur={handleBlur}
              value={values.order}
            />
            <Form.Text>
              The sections/tabs will be ordered by this - e.g. 1 2 3.
            </Form.Text>
          </Form.Group>
          <Button type="submit" disabled={isSubmitting}>
            Save section
          </Button>
        </Form>
      )}
    </Formik>
  );
};

export default NewSection;
