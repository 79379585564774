import React from "react";
import { Image } from "react-bootstrap";
import "./Loading.scss";

const Loading: React.FunctionComponent = () => (
  <div className="Loading">
    <Image
      className="animate__animated animate__bounce animate__infinite"
      src="/images/penguin.svg"
      width="50"
      alt="loading"
    />
  </div>
);

export default Loading;
